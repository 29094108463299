body {
  margin: 0;
  background-color: #ddd;
  #content {
    margin-left: 300px;
  }
  #mainmenu {
    width: 300px;
    a {
      color: inherit;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .MuiTooltip-tooltip {
    font-size: 0.8rem;
  }
}
